export type Map<T> = { [key: string]: T }; // | undefined
export type AuthToken = string;
export type TimeInMilliSeconds = number;
export type Id = string;

export interface RequiredOpconQueryPayload {
    asset_ids?: Id[];
    driver_ids?: Id[];
    from: string;
    to: string;
    facets?: string[];
    group_by?: string[];
}

type RequiredPerformQueryPayload = {
    vehicle_ids: Id[];
    from: Date;
    to: Date;
    driver_ids: (Id | 'NO_DRIVER_CARD')[];
};

export type PositionQueryPayload = {
    assetIds: Id[];
    dateRange: { start: Date; end: Date };
};

export type PerformQueryPayload =
    | RequiredPerformQueryPayload
    | Omit<RequiredPerformQueryPayload, 'driver_ids'>
    | Omit<RequiredPerformQueryPayload, 'vehicle_ids'>;

export type OpconQueryPayload =
    | (RequiredOpconQueryPayload & { asset_ids: Id[] | [Id] })
    | (RequiredOpconQueryPayload & { driver_ids: Id[] | [Id] });

export type VehspecPayload = {
    assetIds: Id[];
    dateRange: { start: Date; end: Date };
};

export type QueryPayload = OpconQueryPayload | PerformQueryPayload | PositionQueryPayload | VehspecPayload;

interface PendingResponse {
    status: 'pending';
}

interface SuccessResponse {
    status: 'success';
}

interface ErrorResponse {
    status: 'error';
}

export type ApiResponse = (PendingResponse | SuccessResponse | ErrorResponse) & {
    id: string;
    status_messages: string[];
};

export type StatisticsReport = {
    //TODO: fix me once we resolve the issue with having invalid min/max values.
    attribute: string;
    min: number | unknown;
    max: number | unknown;
    average: number | undefined;
};

export type Signal<T> = {
    value: T;
    unit: 'noUnit';
    accuracy: 'high';
};

// TODO: create opcon entity / perform entity or maybe singals

export interface GenericSignals {
    vehicleIds?: Signal<Id[]>;
    driverIds?: Signal<Id[]>;

    [key: string]: Signals | undefined;
}

export interface OpconSignals {
    route: Signal<Route>;
    topology: Signal<Score>;
    traffic: Signal<Score>;
    weight: Signal<Score>;
    range: Signal<Score>;
}

export type VehspecEntity = {
    manufacturer: string | null;
    vehicleId: Id;
    cabin?: string | null | number;
    vehicleType?: string | null;
    motor?: string | null | number;
    output?: string | null | number;
    emission?: string | null | number;
    gearing?: string | null | number;
    axisRatio?: string | null | number;
    wheelBase?: string | null | number;
    fuelTanks: (string | null | number)[];
    ureaTank?: string | null | number;
    body?: string | null | number;
};

export type Entity = {
    id: string;
    signals: GenericSignals;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type SignalValue = Id[] | string | object | Route | number | Date | Score | Coordinates[];

export type Signals = Signal<SignalValue>;

export type CardNumber = string;
export type Vin = string;

export type Driver = {
    driverId: Id;
    groupIds: Id[];
    firstName?: string | null;
    lastName?: string | null;
    displayName: string;
    name: string | unknown | null;
    fleetId: Id | undefined;
    id: Id;
    legacyRioId: Id | null;
    cardNumber: CardNumber | null;
    status: string;
};

export enum System {
    TBM3 = 'tbm3',
    TBM2 = 'tbm2',
    VCM = 'vcm',
    FLEET_TECH = 'fleet-tech',
    IDEM_TELEMATICS = 'idem-telematics',
    OCU3 = 'ocu3',
    RFMS = 'rfms',
    TOUR_EXECUTION_USER_DEVICE = 'tour-execution-user-device',
    TRAFFILOG = 'traffilog',
    TRUCK_LOGIN_DEVICE = 'truck-login-device',
    WABCO_TELEMATICS = 'wabco-telematics',
    CM4 = 'cm4',
    NO_SYSTEM = 'NO_SYSTEM',
}

export type Brand = string; // TODO: restrict

export type Vehicle = {
    status: VehicleStatus;
    type: VehicleType;
    segment: VehicleSegment;
    system: System;
    id: Id;
    groupIds: Id[];
    fleetId: Id;
    vehicleId: Id;
    vin: Vin;
    brand: Brand | null;
    name: string;
    customerVehicleName?: string;
    fuelType: VehicleFuelType;
};

export type VehicleGroup = {
    id: Id;
    entityIds?: string[];
    type?: string;
    name: JSX.Element | string;
    position?: 'last' | undefined;
    disabled?: boolean;
    className?: string;
};

export type Road = {
    mileage: number;
    urban: boolean;
    highestRoadType: string;
    country: string;
    name: string;
};

export type Route = {
    roads: Road[];
    evaluatedMileage: number;
};

export type Score = {
    evaluatedMileage: number;
};

export type Coordinates = {
    lat: number;
    lng: number;
};

export enum VehicleType {
    BUS = 'BUS',
    TRAILER = 'TRAILER',
    TRUCK = 'TRUCK',
    VAN = 'VAN',
}

export enum VehicleSegment {
    CITY = 'CITY',
    COACH = 'COACH',
    INTER_CITY = 'INTER_CITY',
    UNKNOWN = 'UNKNOWN',
}

export enum VehicleFuelType {
    DIESEL = 'DIESEL',
    ELECTRIC = 'ELECTRIC',
    GAS = 'GAS',
    HYDROGEN = 'HYDROGEN',
    UNKNOWN = 'UNKNOWN',
}

export enum VehicleStatus {
    ARCHIVED = 'archived',
    ACTIVE = 'active',
}

export * from './reportConfiguration';

import { extractValueFromSignal, getSignal, VehicleFuelType } from '@api/index';
import { getPerformVehicles } from '@common/permissions/selectors';
import {
    getSelectedDriverGroupIds,
    getSelectedDriverIds as getSelectedDriverIdsFromTree,
    getSelectedFuelTypes,
    getSelectedVehicleGroupIds,
    getSelectedVehicleIds as getSelectedVehicleIdsFromTree,
} from '@features/tree/selectors';
import _ from 'lodash';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';

import { ConfigStatePart, PermissionsStatePart } from '../setup/types';
import {
    AnonymousDriver,
    DriverType,
    Entity,
    EssentialDriver,
    HydratedEntity,
    Id,
    Map,
    NoCardDriver,
    PerformDriver,
    RawDriver,
    Signals,
    Vehicle,
} from '../types';
import { DataStatePart } from './../setup/types';
import { Loadable, LoadableType } from './loadable';

const stateSelector = (state: DataStatePart) => state;

export const getGroups = (state: DataStatePart) => state.data.groups;
export const getVehicles = (state: DataStatePart) => state.data.vehicles;
export const getDrivers = (state: DataStatePart) => state.data.drivers;

export const getElectricVehicles = (state: DataStatePart) =>
    Object.values(getVehicles(state)).reduce((acc: string[], vehicle: Vehicle) => {
        if (vehicle.fuelType === VehicleFuelType.ELECTRIC) {
            acc.push(vehicle.id);
        }

        return acc;
    }, []);

export const getVehiclesSegments = (state: DataStatePart) => {
    const allVehicles = Object.values(getVehicles(state));
    const segments = new Set(allVehicles.map(vehicle => vehicle.segment));
    return segments;
};

export const requestsByKey = (state: DataStatePart) => state.data.requests;
export const getRequest = (state: DataStatePart, requestId: string) =>
    _.get(requestsByKey(state), requestId, Loadable.createNotRequested<string[]>());

const hideDriverDetails = (driver: RawDriver): EssentialDriver => ({
    ...driver,
    cardNumber: null,
    firstName: null,
    lastName: null,
    displayName: null,
    type: DriverType.ESSENTIAL,
});

export const mapEntities = (
    baseEntity: Entity | undefined = { id: '', signals: {} },
    vehicles: Map<Vehicle>,
    drivers: Map<RawDriver>,
    performVehicles: Id[]
): HydratedEntity => {
    let entityVehicleIds: string[] = [];
    let entityDriverIds: string[] = [];
    if (baseEntity?.signals?.vehicleIds) {
        entityVehicleIds = extractValueFromSignal(baseEntity.signals.vehicleIds);
    }

    if (baseEntity?.signals?.driverIds) {
        entityDriverIds = extractValueFromSignal(baseEntity.signals.driverIds);
    }
    const isNoPerformVehicle = entityVehicleIds.length === 1 && !_.includes(performVehicles, entityVehicleIds[0]);

    const mapDriver = (driverId: Id) => {
        if (driverId === 'NO_DRIVER_CARD') return { type: DriverType.NO_DRIVER_CARD } as NoCardDriver;

        const mappedDriver = drivers[driverId];
        if (!mappedDriver) return { type: DriverType.ANONYMOUS, driverId: driverId } as AnonymousDriver;
        if (isNoPerformVehicle) return hideDriverDetails(mappedDriver);

        return mappedDriver as PerformDriver;
    };

    const mappedDrivers = entityDriverIds.map(mapDriver).filter(driver => driver.type !== DriverType.ESSENTIAL);

    return {
        ...baseEntity,
        signals: { ...baseEntity.signals },
        vehicles: entityVehicleIds.map(vehicle => vehicles[vehicle]).filter(vehicle => vehicle),
        drivers: mappedDrivers,
    };
};

export const entitiesByKey = (state: DataStatePart) => state.data.entities;

const createEntitySelectorForRequest = (requestId: string) => {
    const createRequestComparatorSelector = createSelectorCreator<
        (prevState: DataStatePart, newState: DataStatePart) => boolean
    >(
        // eslint-disable-next-line @typescript-eslint/ban-types
        defaultMemoize as <F extends Function>(
            func: F,
            equalityCheck: (prevState: DataStatePart, newState: DataStatePart) => boolean
        ) => F,
        (prevState: DataStatePart, newState: DataStatePart) =>
            Loadable.isEqual(getRequest(prevState, requestId), getRequest(newState, requestId))
    );
    return createRequestComparatorSelector([stateSelector], (state: DataStatePart) => {
        const entities = entitiesByKey(state);
        const request = getRequest(state, requestId);
        return Loadable.map(request, keys => keys.map(k => entities[k]).filter(entity => entity));
    });
};

const makeEntitiesForRequestSelector = _.memoize((requestId: string) => {
    const rawEntitiesForRequest = createEntitySelectorForRequest(requestId);

    return createSelector(
        [rawEntitiesForRequest, getDrivers, getVehicles, getPerformVehicles],
        (rawEntities: LoadableType<Entity[]>, drivers, vehicles, performVehicles) =>
            Loadable.map(rawEntities, entities =>
                entities.map(entity => mapEntities(entity, vehicles, drivers, performVehicles))
            )
    );
});

export const entitiesForRequest = (state: DataStatePart & PermissionsStatePart, key: string) =>
    makeEntitiesForRequestSelector(key)(state);

export const vehSpecForRequest = (state: DataStatePart, requestId: Id) =>
    state.data.vehicleSpecifications[requestId] || Loadable.createNotRequested();

export const statisticsForRequest = (state: DataStatePart, requestId: string) =>
    state.data.statistics[requestId] || Loadable.createNotRequested();

export const backendConfig = (state: ConfigStatePart, key: string) => state.config.backend[key];

function mergeAssetsAndSelectedGroups(
    assets: Map<{ groupIds: Id[]; id: Id }>,
    selectedGroupIds: Id[],
    selectedAssetIdsFromTree: Id[]
) {
    const assetsArray = Object.values(assets);
    return Array.from(
        new Set(
            selectedGroupIds.reduce(
                (acc: Id[], groupId: Id) => [
                    ...acc,
                    ...assetsArray.filter(asset => asset.groupIds.includes(groupId)).map(asset => asset.id),
                ],
                selectedAssetIdsFromTree
            )
        )
    );
}

function mergeVehicleAssetsAndSelectedGroups(
    assets: Map<{ groupIds: Id[]; id: Id; fuelType: VehicleFuelType }>,
    selectedGroupIds: Id[],
    selectedAssetIdsFromTree: Id[],
    selectedFuelTypes: VehicleFuelType[]
) {
    const assetsArray = Object.values(assets);
    return Array.from(
        new Set(
            selectedGroupIds.reduce(
                (acc: Id[], groupId: Id) => [
                    ...acc,
                    ...assetsArray
                        .filter(
                            asset =>
                                asset.groupIds.includes(groupId) &&
                                selectedFuelTypes?.includes(asset.fuelType ?? VehicleFuelType.UNKNOWN)
                        )
                        .map(asset => asset.id),
                ],
                selectedAssetIdsFromTree
            )
        )
    );
}

export const getSelectedVehicleIds = createSelector(
    [getVehicles, getSelectedVehicleGroupIds, getSelectedVehicleIdsFromTree, getSelectedFuelTypes],
    mergeVehicleAssetsAndSelectedGroups
);

export const getSelectedDriverIds = createSelector(
    [getDrivers, getSelectedDriverGroupIds, getSelectedDriverIdsFromTree],
    mergeAssetsAndSelectedGroups
);

export const containsElectricVehicleType = createSelector([getSelectedFuelTypes], fuelTypes => {
    return fuelTypes.includes(VehicleFuelType.ELECTRIC);
});

export const getSelectedVehicleFuelTypes = createSelector(
    [getVehicles, getSelectedVehicleIds],
    (allVehicles: Map<Vehicle>, selectedVehicleIds: Id[]) => {
        const selectedVehiclesFuelType = new Set([
            ...Object.values(allVehicles)
                .filter((vehicle: Vehicle) => selectedVehicleIds.includes(vehicle.id))
                .map(vehicle => vehicle.fuelType),
        ]);
        return selectedVehiclesFuelType;
    }
);

export const hasSomePerformVehicleSelected = createSelector(
    [getSelectedVehicleIds, getPerformVehicles],
    (selectedVehicles, performVehicles) => _.intersection(selectedVehicles, performVehicles).length > 0
);
export const hasOnlyPerformVehiclesSelected = createSelector(
    [getSelectedVehicleIds, getPerformVehicles],
    (selectedVehicles, performVehicles) => _.difference(selectedVehicles, performVehicles).length === 0
);

const filterOutUndefinedValues = (arg: {
    value: Signals | undefined;
    date: Date | undefined;
}): arg is { value: Signals; date: Date } => !_.isUndefined(arg.value) || !_.isUndefined(arg.date);

const makeEntityGraphSelection = _.memoize((requestId: string) => {
    const statisticsForRequestSelector = (state: DataStatePart) => statisticsForRequest(state, requestId);

    return createSelector([statisticsForRequestSelector, entitiesForRequest], (statistics, entities) =>
        Loadable.combine(
            (stats, entities: HydratedEntity[]) =>
                stats.map(s => ({
                    ...s,
                    //TODO: make resilient to change, leaking too much information
                    values: entities
                        .map(entity => ({
                            value: _.get(entity.signals, s.attribute, undefined),
                            date: getSignal<Date | undefined>(entity, 'start', undefined),
                        }))
                        .filter(filterOutUndefinedValues),
                })),
            statistics,
            entities
        )
    );
});

export const entitiesForGraphSelection = (state: DataStatePart & PermissionsStatePart, key: string) =>
    makeEntityGraphSelection(key)(state, key);

export const isInitialized = (state: DataStatePart) =>
    state.data.vehiclesInitialized && state.data.driversInitialized && state.data.groupsInitialized;

import AutoSuggest, { AutoSuggestSuggestion, SelectedSuggestion } from '@rio-cloud/rio-uikit/AutoSuggest';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const Suggest = ({
    suggestions = [],
    placeholderMessage = '',
    setSelection,
}: {
    suggestions: AutoSuggestSuggestion[];
    placeholderMessage: string;
    setSelection: (suggestion?: AutoSuggestSuggestion) => void;
}) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState<AutoSuggestSuggestion[]>(suggestions);
    const [value, setValue] = useState('');

    useEffect(() => {
        setFilteredSuggestions(suggestions);
    }, [suggestions]);

    const handleSuggestionsFetchRequested = ({ value }: { value: string }) => {
        setValue(value);
        setFilteredSuggestions(
            suggestions.filter(suggestion => suggestion.label?.toLowerCase().includes(value.toLowerCase()))
        );
    };

    const handleSuggestionSelected = (_event: unknown, { suggestion }: SelectedSuggestion) => {
        setSelection(suggestion);
    };

    const handleClear = () => {
        setValue('');
        setSelection();
        setFilteredSuggestions(suggestions);
    };

    const renderSuggestion = (suggestion: AutoSuggestSuggestion): JSX.Element => {
        return (
            <div>
                <span className={`rioglyph rioglyph-${suggestion.icon}`} />
                <span>{suggestion.label}</span>
            </div>
        );
    };

    const inputProps = {
        onChange: _.noop,
        onClear: handleClear,
        onBlur: _.noop,
        onFocus: _.noop,
        placeholder: placeholderMessage,
        value: value,
        tabIndex: 1,
    };

    return (
        <React.Fragment>
            <AutoSuggest
                className="min-width-300"
                inputProps={inputProps}
                suggestions={filteredSuggestions}
                noItemMessage={<FormattedMessage id="noData" />}
                onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
                onSuggestionSelected={handleSuggestionSelected}
                renderSuggestion={renderSuggestion}
                openOnFocus
            />
        </React.Fragment>
    );
};

export default Suggest;

import colors from '@rio-cloud/rio-uikit/Colors';
import { useContext } from 'react';

import { GraphContext } from './GraphDataProvider';
import { DataEntry } from './types';

const COLOR = colors['color-highlight-dark'];

export default function SingleDataPoint({ selectedElement }: { selectedElement: DataEntry | undefined }) {
    const { xScale: x, leftYScale: y } = useContext(GraphContext);

    if (selectedElement) {
        return (
            <circle
                stroke={COLOR}
                r={4}
                cy={y(selectedElement.leftUpY)}
                cx={x(selectedElement.x as number)}
                className="dataPoint"
            />
        );
    }
    return null;
}

import _ from 'lodash';
import moment, { Moment } from 'moment';
import { Driver, Vehicle } from 'src/types';

export const formatNoUnit = (value: string | null | undefined) => (_.isNil(value) ? value : `(${value})`);

export const formattedNumber = (value?: number | string, digits?: number, locale?: string) => {
    if (_.isNil(value) || !_.isNumber(value)) {
        return '-';
    }
    return value.toLocaleString(locale, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    });
};

export const factoredNumber = (value: number | string | undefined, factor: number, digits: number, locale: string) => {
    if (_.isNil(value) || !_.isNumber(value)) {
        return '-';
    }
    const num = value * factor;
    return num.toLocaleString(locale, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    });
};

export const formatSecondsToTime = (seconds: number | string | null | undefined) => {
    if (_.isNil(seconds) || !_.isNumber(seconds)) {
        return '-';
    }
    const minutesTotal = Math.floor(seconds / 60);
    const hours = Math.floor(minutesTotal / 60);
    const minutes = minutesTotal % 60;
    if (minutes < 10 && hours < 1) {
        const hhmmss = new Date(seconds * 1000).toISOString().slice(11, 19);

        return hhmmss;
    }
    return [`${hours}`.padStart(2, '0'), `${minutes}`.padStart(2, '0')].join(':');
};

export const percentNumber = (value: number | undefined, locale: string) => factoredNumber(value, 100, 1, locale);

/**
 * Convert a given timestamp to the format the backend requires.
 * see http://momentjs.com/docs/#/displaying/format/
 *
 * @param {Object} timestamp - The frontend timestamp.
 * @returns {String} - The convert timestamp.
 */
export const convertTimestampToServerFormat = (timestamp: string | Moment) => {
    return moment.isMoment(timestamp) ? timestamp.toISOString(true) : timestamp;
};

/**
 * Resolve visible name for a vehicle. In order, if possible: "Name", "Customer vehicle name", "VIN", "-"
 * @param {Object} vehicle - Vehicle data object.
 * @returns {string} Name / label for a vehicle.
 */
export const extractVehicleName = (vehicle: Vehicle) => {
    if (vehicle) {
        if (vehicle.customerVehicleName) {
            return vehicle.customerVehicleName;
        }
        if (vehicle.name) {
            return vehicle.name;
        }
        if (vehicle.vin) {
            return vehicle.vin;
        }
    }
    return '-';
};

export const extractDriverName = (driver: Driver, { lastNameFirst = false } = {}) => {
    if (driver) {
        if ('lastName' in driver && driver.lastName && 'firstName' in driver && driver.firstName) {
            if (lastNameFirst) {
                return `${driver.lastName}, ${driver.firstName}`;
            } else {
                return `${driver.firstName} ${driver.lastName}`;
            }
        }
        if ('lastName' in driver && driver.lastName) {
            return driver.lastName;
        }
        if ('firstName' in driver && driver.firstName) {
            return driver.firstName;
        }
        if ('displayName' in driver && driver.displayName) {
            return driver.displayName;
        }
    }
    return '-';
};

import { Text, View } from '@react-pdf/renderer';
import { getPluralization } from '@utils/getPluralization';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Driver, Vehicle } from '../../../types';

export default function VehicleSummary({ vehicles, drivers }: { vehicles: Vehicle[]; drivers: Driver[] }) {
    const pluralizedDriver = getPluralization('driver', 'drivers', drivers.length);
    const pluralizedVehicle = getPluralization('vehicle', 'vehicles', vehicles.length);

    return (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <FormattedMessage id="print.with" />
            <Text> </Text>
            {vehicles.length ? (
                <React.Fragment>
                    <Text style={{ fontFamily: 'Bold' }}>
                        <FormattedMessage
                            id="vehicleCount"
                            values={{
                                vehicleCount: vehicles.length,
                                pluralizedVehicle,
                            }}
                        />
                    </Text>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <FormattedMessage id="fleetOf" />
                    <Text style={{ fontFamily: 'Bold' }}>
                        <FormattedMessage
                            id="vehicleCount"
                            values={{
                                vehicleCount: vehicles.length,
                                pluralizedVehicle,
                            }}
                        />
                    </Text>
                </React.Fragment>
            )}
            <Text> </Text>
            <FormattedMessage id="print.and.team" />
            <Text> </Text>
            {drivers.length ? (
                <React.Fragment>
                    <Text style={{ fontFamily: 'Bold' }}>
                        <FormattedMessage
                            id="driverCount"
                            values={{
                                driverCount: drivers.length,
                                pluralizedDriver,
                            }}
                        />
                    </Text>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <FormattedMessage id="teamOf" />
                    <Text style={{ fontFamily: 'Bold' }}>
                        <FormattedMessage
                            id="driverCount"
                            values={{
                                driverCount: drivers.length,
                                pluralizedDriver,
                            }}
                        />
                    </Text>
                </React.Fragment>
            )}
        </View>
    );
}

import { getFeatureToggles } from '@common/permissions/selectors';
import { accessTokenStored } from '@common/tokenHandling/actions';
import { getAccessToken } from '@common/tokenHandling/selectors';
import { backendConfig } from '@data/selectors';
import _ from 'lodash';
import { call, debounce, delay, put, race, select, takeLatest } from 'redux-saga/effects';

import { configureReporting } from '../../setup/errorReporting';
import { ThenArg } from '../../types';
import { infoNotification } from '../ui/notificationSaga';
import { SETTINGS_CURRENT_VERSION } from './migration/constants';
import migrateSelectedDrivers from './migration/migrateDriver_2.migration';
import migrateKeys from './migration/migrateKeys_1.migration';
import { loadSettingsFailed, loadSettingsSuccess, Settings, updateSavingFailed, updateSetting } from './reducer';
import { getSettings } from './selectors';

const { captureException } = configureReporting(window, import.meta.env);

export function* saveSettingsToStorage<T>(key: string, setting: T) {
    const settings: ReturnType<typeof getSettings> = yield select(getSettings);
    const oldValue = settings[key];
    if (JSON.stringify(oldValue) !== JSON.stringify(setting)) {
        yield put(updateSetting({ key, value: setting }));

        if (key === 'enablePerform3') {
            yield delay(200);
            yield call(() => window.location.reload());
        }
    }
}

export function* loadSettingsFromStorage<T>(key: string, fallback: T) {
    const settings: ReturnType<typeof getSettings> = yield select(getSettings);
    const value = settings[key];

    return _.isUndefined(value) ? fallback : value;
}

const isJSONResponse = (response: Response) => {
    const contentType = response.headers.get('content-type');
    return contentType && contentType.indexOf('application/json') !== -1;
};

export const apiCall = (url: string, args: RequestInit): Promise<unknown> => {
    return fetch(url, args).then(response => {
        if (response.ok) {
            if (isJSONResponse(response)) {
                return response.json();
            }
            return response.text();
        }
        if (response.status === 404) {
            return null;
        }
        throw Error(`error response during settings ${args.method} with ${response.status}: ${response.statusText}`);
    });
};

export function* api(url: string) {
    let authToken: ReturnType<typeof getAccessToken> = yield select(getAccessToken);

    if (!authToken) {
        throw new Error('no authToken while loading settings!');
    }

    return (yield call(apiCall, url, {
        method: 'GET',
        headers: { Authorization: `Bearer ${authToken}` },
    })) as ThenArg<ReturnType<typeof apiCall>>;
}

export function* load() {
    const url: ReturnType<typeof backendConfig> = yield select(backendConfig, 'SETTINGS_SERVICE');
    try {
        const { response, timeout } = yield race({ response: call(api, url), timeout: delay(4000) });
        if (timeout) throw new Error('Timeout during race');
        if (response) {
            const unMigratedSettings = response.reduce(
                (acc: Settings, { key, value }: { key: string; value: string }) => ({
                    ...acc,
                    [key]: JSON.parse(value),
                }),
                {}
            );

            const oldSettingsVersion = unMigratedSettings.version || 0;

            const settings1: ThenArg<ReturnType<typeof migrateKeys>> = yield call(
                migrateKeys,
                unMigratedSettings,
                oldSettingsVersion
            );
            const settings2: ThenArg<ReturnType<typeof migrateKeys>> = yield call(
                migrateSelectedDrivers,
                settings1,
                oldSettingsVersion
            );

            settings2.version = SETTINGS_CURRENT_VERSION;

            yield put(loadSettingsSuccess({ settings: settings2 }));
        }
    } catch (e) {
        yield put(loadSettingsFailed());
        yield delay(1000);
        yield call(infoNotification, 'info.settingsCouldNotBeRestored');
        yield call(captureException, e);
    }
}

export function* update() {
    const existingSettings: ReturnType<typeof getSettings> = yield select(getSettings);
    const authToken: ReturnType<typeof getAccessToken> = yield select(getAccessToken);
    const url: ReturnType<typeof backendConfig> = yield select(backendConfig, 'SETTINGS_SERVICE');

    const transformed = Object.entries(existingSettings).map(([key, value]) => ({ key, value: JSON.stringify(value) }));

    try {
        yield call(apiCall, url, {
            method: 'PUT',
            body: JSON.stringify(transformed),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
        });
    } catch (e) {
        captureException(e as Error);
        yield put(updateSavingFailed());
    }
}

export default function* root() {
    const { persistSettings } = yield select(getFeatureToggles);
    if (!persistSettings) {
        return;
    }
    yield takeLatest([accessTokenStored], load);
    yield debounce(100, updateSetting, update);
}

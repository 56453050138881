import _ from 'lodash';

const getAverage = (values: Array<unknown>) => _.mean(_.filter(values, _.isNumber));

const getWeightedAverage = (weightingsPerDay: Array<unknown>, valuesPerDay: _.List<unknown>) => {
    const filteredWeightValuePairs = _.zip(weightingsPerDay, valuesPerDay).filter(
        ([weight, value]) => _.isNumber(value) && _.isNumber(weight)
    ) as Array<[number, number]>;
    const weightedValuesSum = _.sumBy(
        filteredWeightValuePairs,
        ([weight, value]) => (value as number) * (weight as number)
    );
    const weightsSum = _.sumBy(filteredWeightValuePairs, _.head as (a: number[]) => number);

    return weightsSum ? weightedValuesSum / weightsSum : 0;
};

const WEIGHTED_AVERAGE_BY: Record<string, string> = {
    idling_consumption: 'idling_time',
    average_weight: 'mileage',
    co_2_emission: 'mileage',
    distance_fuel_consumption: 'fuel_consumption',
    driving_consumption: 'mileage',
    fuel_consumption: 'mileage',
    fuel_efficiency: 'mileage',
    operating_fuel_consumption: 'mileage',
    average_speed: 'driving_time',
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const calculateAverage = (key: string, groupedValues: { key: string; values: unknown[] }[], values: Array<unknown>) => {
    const weightedByKey = WEIGHTED_AVERAGE_BY[key];
    const weightedValues = _.get(_.find(groupedValues, ['key', weightedByKey]), 'values', []);

    if (values.filter(_.isUndefined).length === values.length) {
        return undefined;
    }

    if (weightedValues.length === values.length) {
        return getWeightedAverage(weightedValues, values);
    }

    return getAverage(values);
};

export default calculateAverage;

import ErrorBoundary from '@components/ErrorBoundry';
import Area from '@components/graphComponents/Area';
import XAxis from '@components/graphComponents/DatedXAxis';
import Day from '@components/graphComponents/Day';
import { DataPoint } from '@components/graphComponents/Graph';
import GraphDataProvider from '@components/graphComponents/GraphDataProvider';
import SingleDataPoint from '@components/graphComponents/SingleDataPoint';
import { YAxisLines, YAxisTicks } from '@components/graphComponents/YAxis';
import { Loadable } from '@data/loadable';
import colors from '@rio-cloud/rio-uikit/Colors';
import { scaleLinear } from 'd3-scale';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import useElevationData, { ceilDate, ElevationData, floorDate } from '../../data/hooks/useElevationData';
import { DateRange, Id } from '../../types';
import GraphOverlay from './components/GraphOverlay';
import LoadingGraph from './LoadingGraph';
import { axisTimeDiff, createTickFormatter, interval, timeDiff } from './utils/elevationUtils';

const getMaxYValue = (data: ElevationData[]) => _.head([...data].sort((a, b) => b.max - a.max))?.max || 0;
const getMinYValue = (data: ElevationData[]) => _.head([...data].sort((a, b) => b.min - a.min).reverse())?.min || 0;

const MAX_COLOR = colors['color-highlight-dark'];
const MIN_COLOR = colors['color-highlight-light'];

const LegendItem: React.FC<{ color: string }> = ({ color, children }) => {
    return (
        <li style={{ color }}>
            <span
                className="width-10 height-10 rounded-circle display-inline-block margin-right-5"
                style={{ background: color }}
            />
            {children}
        </li>
    );
};

const Legend = () => {
    return (
        <ul
            data-test="legend"
            className="position-absolute right-0 margin-15 padding-0 bg-white z-index-1"
            style={{ listStyle: 'none' }}
        >
            <LegendItem color={MAX_COLOR}>
                <FormattedMessage id="maxAltitude" />
            </LegendItem>
            <LegendItem color={MIN_COLOR}>
                <FormattedMessage id="minAltitude" />
            </LegendItem>
        </ul>
    );
};

const Actions = ({ onScale }: { onScale: () => void }) => {
    return (
        <ul
            data-test="actions"
            className="position-absolute right-0 bottom-0 z-index-1 margin-15 padding-0 padding-bottom-25"
            style={{ listStyle: 'none' }}
        >
            <li>
                <button onClick={onScale} type="button" className="btn btn-default btn-icon-only margin-bottom-2">
                    <span className="rioglyph rioglyph-resize-vertical" />
                </button>
            </li>
        </ul>
    );
};

const ElevationGraph = ({
    dateRange: rawDateRange,
    vehicleIds,
    driverIds,
    intl,
}: {
    dateRange: DateRange;
    vehicleIds: Id[];
    driverIds: (Id | null)[];
    intl: IntlShape;
}) => {
    const [dimensions, setDimensions] = useState({ height: 200, width: 0, margin: 40 });

    useEffect(() => {
        const maxWidth = 950;
        setDimensions(prevDimensions => ({
            ...prevDimensions,
            width: Math.min(document.body.clientWidth - 40, maxWidth),
        }));
    }, []);
    const [hoverInfo, setHover] = useState<{ leftX: number; leftUpY: number; data: ElevationData } | undefined>(
        undefined
    );
    // if we zero the graph or not.
    const [isZeroed, setIsZeroed] = useState<boolean>(true);
    const debouncedSetHover = useRef(_.debounce(hoverInfo => setHover(hoverInfo), 10));
    const unit = timeDiff(rawDateRange);
    const dateRange = {
        start: floorDate(rawDateRange.start, unit),
        end: ceilDate(rawDateRange.end, unit),
    };
    const data = useElevationData({ dateRange, vehicleIds, driverIds, unit });
    const onScale = () => setIsZeroed(!isZeroed);

    return (
        <div data-test="elevation-graph" style={{ width: dimensions.width }}>
            {Loadable.cata(
                data,
                (mappedData: ElevationData[]) => {
                    const max = getMaxYValue(mappedData);
                    const min = getMinYValue(mappedData);
                    const leftYScale = scaleLinear()
                        .domain(isZeroed ? [Math.min(max + 300, 4000), 0] : [max + 20, Math.max(min - 20, 0)])
                        .range([0, dimensions.height - dimensions.margin]);

                    const minData = mappedData.map(data => ({
                        ...data,
                        x: data.date,
                        leftUpY: data.min,
                    }));

                    const maxData = mappedData.map(data => ({
                        ...data,
                        x: data.date,
                        leftUpY: data.interpolated ? data.min : data.max,
                    }));

                    const defaultProviderPros = {
                        leftYScale,
                        formatDate: intl.formatDate,
                        formatMessage: intl.formatMessage,
                        dimensions,
                    };

                    const maxArea = (
                        <GraphDataProvider {...defaultProviderPros} data={maxData}>
                            {maxData.length === 1 ? <DataPoint /> : <Area fill={MAX_COLOR} />}
                        </GraphDataProvider>
                    );
                    const minArea = (
                        <GraphDataProvider {...defaultProviderPros} data={minData}>
                            {minData.length === 1 ? <DataPoint /> : <Area fill={MIN_COLOR} />}
                        </GraphDataProvider>
                    );

                    const maxHoverData = hoverInfo
                        ? {
                              x: hoverInfo.data.date,
                              leftUpY: hoverInfo.data.interpolated ? hoverInfo.data.min : hoverInfo.data.max,
                          }
                        : undefined;
                    const maxHoverDataPoint = (
                        <GraphDataProvider {...defaultProviderPros} data={maxData}>
                            <SingleDataPoint selectedElement={maxHoverData} />
                        </GraphDataProvider>
                    );

                    const minHoverData = hoverInfo
                        ? { x: hoverInfo.data.date, leftUpY: hoverInfo.data.min }
                        : undefined;
                    const minHoverDataPoint = (
                        <GraphDataProvider {...defaultProviderPros} data={minData}>
                            <SingleDataPoint selectedElement={minHoverData} />
                        </GraphDataProvider>
                    );

                    return (
                        <ErrorBoundary>
                            <div
                                className="graph bg-white margin-bottom-1 position-relative overflow-hidden"
                                style={{ height: dimensions.height }}
                            >
                                <Legend />
                                <Actions onScale={onScale} />
                                {hoverInfo && (
                                    <GraphOverlay
                                        key={'overlay'}
                                        {...hoverInfo}
                                        parentDimensions={{
                                            width: dimensions.width,
                                            height: dimensions.height - dimensions.margin * 4,
                                        }}
                                    />
                                )}
                                <svg
                                    width={'100%'}
                                    height={'100%'}
                                    preserveAspectRatio="xMinYMin meet"
                                    viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
                                >
                                    <GraphDataProvider {...defaultProviderPros} data={minData}>
                                        <YAxisLines />
                                        <XAxis
                                            dateRange={dateRange}
                                            selectedElement={hoverInfo?.data?.date}
                                            tickFormatter={createTickFormatter(intl.formatDate, intl.formatMessage)}
                                            timeDiff={axisTimeDiff}
                                            timeIntervalType={interval}
                                        />
                                        <g data-test="max-area">{maxArea}</g>
                                        <g data-test="min-area">{minArea}</g>

                                        <g transform="translate(-8 0)">
                                            <YAxisTicks
                                                formatter={(value: number) =>
                                                    `${intl.formatNumber(value)} ${intl.formatMessage({
                                                        id: 'unit.m',
                                                    })}`
                                                }
                                            />
                                        </g>
                                        {minHoverDataPoint}
                                        {maxHoverDataPoint}

                                        <Day onClick={() => {}} onHover={args => debouncedSetHover.current(args)} />
                                    </GraphDataProvider>
                                </svg>
                            </div>
                        </ErrorBoundary>
                    );
                },
                () => null,
                () => (
                    <LoadingGraph dimensions={dimensions} />
                ),
                () => (
                    <LoadingGraph dimensions={dimensions} />
                )
            )}
        </div>
    );
};

export default injectIntl(ElevationGraph);
